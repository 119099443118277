<template>
  <div>
    <div class="fz-16 openSans-300 mb-4 txt-primary-black" v-text="label"></div>
    <v-select class="my-0" :items="items" :label="placeholder" v-model="selected" :multiple="true" :hide-details="true" dense outlined @input="onChange">
      <template v-slot:prepend-item>
        <v-list-item ripple @click="selectAll()">
          <v-list-item-action>
            <v-checkbox v-model="allSelected"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t('$vuetify.common.labels.selectAll') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-1"></v-divider>
      </template>
    </v-select>
    <v-chip v-for="item in selected" :key="item" class="fz-14 openSans-400 filter-chips mr-2 mt-2" label>{{ item }}
      <v-icon @click="removeItem(item)" class="chip-button" dense right>mdi-close-circle-outline</v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {

  name: 'FilterSelect',

  props: {
    model: Array,
    label: String,
    items: Array,
    placeholder: String,
    onChange: Function
  },
  data () {
    return {
      allSelected: false
    }
  },
  methods: {
    selectAll() {
      this.allSelected = !this.allSelected
      this.selected = this.allSelected ? [...this.items] : []
      this.onChange()
    },
    removeItem(item) {
      const index = this.selected.indexOf(item)
      if (index > -1) {
        this.selected.splice(index, 1)
        this.allSelected = false
      }
      this.onChange()
    }
  },
  computed: {
    selected: {
      get() {
        return this.model
      },
      set(value){
        this.$emit('update:model', value)
      }
    }
  }
}
</script>

<style lang="scss">

.chip-button {
    transform: scale(1.3);
    height: 20px !important;
    width: 20px !important;
    line-height: normal;
    font-size: 15px;
}
</style>