<template>
  <v-card elevation="0" class="gray-border-container">
    <v-card-text>
      <div class="action-banner filter-banner d-flex mb-6 flex-wrap">
        <div class="text-h6 flex-grow-1 mr-5" v-text="$vuetify.lang.t('$vuetify.ilm.labels.filters')"></div>
        <div class="filter-buttons d-flex mt-1 justify-space-between">
          <a class="align-center d-flex mr-5 text-no-wrap text-uppercase" @click="clearFilters">{{ $vuetify.lang.t('$vuetify.common.buttons.clearAll') }}</a>
          <a class="align-center d-flex text-no-wrap text-uppercase" @click="applyFilters">{{ $vuetify.lang.t('$vuetify.common.buttons.apply') }}</a>
        </div>
      </div>

      <v-menu ref="menu" v-model="datepickerMenu" :close-on-content-click="false" :return-value.sync="datepickerMenu" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Select a date..." prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker color="primary" v-model="datesRange" no-title scrollable range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="datepickerMenu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="setDates">OK</v-btn>
        </v-date-picker>
      </v-menu>

      <v-checkbox v-model="criteria.starred_only" @change="applyFilters">
        <template v-slot:label>
          <div>
            <v-icon class="fz-16 openSans-400" color="amber lighten-1">mdi-star</v-icon> {{ $vuetify.lang.t('$vuetify.ilm.labels.starredArticlesOnly') }}
          </div>
        </template>
      </v-checkbox>

      <v-divider></v-divider>

      <filter-select class="my-9" :placeholder="$vuetify.lang.t('$vuetify.ilm.labels.placeholderSource')" :label="$vuetify.lang.t('$vuetify.ilm.labels.source')"
                     :items="sources" :model.sync="criteria.sources" :on-change="applyFilters"/>

      <filter-autocomplete class="my-9" :label="$vuetify.lang.t('$vuetify.ilm.labels.journal')" :placeholder="$vuetify.lang.t('$vuetify.ilm.labels.placeholderJournal')"
                           property-name="journal" :model.sync="criteria.journals" :filters="appliedFilters" :on-change="applyFilters"/>

      <filter-autocomplete class="my-9" :label="$vuetify.lang.t('$vuetify.ilm.labels.author')" :placeholder="$vuetify.lang.t('$vuetify.ilm.labels.placeholderAuthor')"
                           property-name="author" :model.sync="criteria.authors" :filters="appliedFilters" :on-change="applyFilters"/>

      <filter-select class="my-9" :placeholder="$vuetify.lang.t('$vuetify.ilm.labels.placeholderArticleType')" :label="$vuetify.lang.t('$vuetify.ilm.labels.articleType')"
                     :items="types" :model.sync="criteria.types" :on-change="applyFilters"/>

      <filter-autocomplete class="my-9" :label="$vuetify.lang.t('$vuetify.ilm.labels.tag')" :placeholder="$vuetify.lang.t('$vuetify.ilm.labels.placeholderTag')"
                           property-name="ontology_tag" :model.sync="criteria.tags" :filters="appliedFilters" :on-change="applyFilters"/>
    </v-card-text>
  </v-card>
</template>

<script>
import Tools from '@/modules/common/utils/Tools.js'

import FilterSelect from '@/modules/intelligentLiteratureMonitoring/components/FilterSelect.vue'
import FilterAutocomplete from '@/modules/intelligentLiteratureMonitoring/components/FilterAutocomplete.vue'

export default {

  name: 'FilterColumn',

  components: { FilterSelect, FilterAutocomplete },
  props: {
    sources: Array,
    types: Array
  },
  data () {
    return {
      criteria: {
        dateRange: {
          startDate: null,
          endDate: null
        },
        starred_only: false,
        sources: [],
        types: [],
        journals: [],
        authors: [],
        tags: []
      },
      datesRange: [],
      datepickerMenu: false,
      appliedFilters:{}
    }
  },
  computed: {
    dates() {
      return Tools.getRageDatePreview(this.criteria.dateRange.startDate, this.criteria.dateRange.endDate)
    },
    formatFilter: function () {
      let filters = {}
      let dates = [this.criteria.dateRange.startDate, this.criteria.dateRange.endDate]
      console.log(dates)
      dates = dates.sort((a, b) => {
        return new Date(a) - new Date(b);
      })
      console.log(dates)
      if (dates[0]) {
        filters.publication_date_start = dates[0]
      }
      if (dates[1]) {
        filters.publication_date_end = dates[1]
      }
      if (this.criteria.sources.length != 0) {
        filters.data_sources = this.criteria.sources
      }
      if (this.criteria.types.length != 0) {
        filters.article_types = this.criteria.types
      }
      if (this.criteria.journals.length != 0) {
        filters.journals = this.criteria.journals
      }
      if (this.criteria.authors.length != 0) {
        filters.author_names = this.criteria.authors
      }
      if (this.criteria.tags.length != 0) {
        filters.ontology_tags = this.criteria.tags
      }
      if (this.criteria.starred_only) {
        filters.starred = true
      }
      console.log(filters)
      return filters
    }
  },
  methods: {
    applyFilters() {
      this.appliedFilters = this.formatFilter
      this.$emit('applyFilters', this.appliedFilters)
    },
    clearFilters() {
      this.criteria.dateRange.startDate = null
      this.criteria.dateRange.endDate = null
      this.datesRange = []
      this.criteria.starred_only = false
      this.criteria.sources = []
      this.criteria.types = []

      this.criteria.journals = []
      this.criteria.authors = []
      this.criteria.tags = []
      this.applyFilters()
    },
    setDates() {
      this.criteria.dateRange.startDate = null
      this.criteria.dateRange.endDate = null
      if (this.datesRange.length == 1) {
        this.criteria.dateRange.startDate = this.datesRange[0]
      }
      if (this.datesRange.length == 2) {
        this.criteria.dateRange.startDate = this.datesRange[0]
        this.criteria.dateRange.endDate = this.datesRange[1]
      }
      this.datepickerMenu = false
      this.applyFilters()
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
</style>
