<template>
  <div class="mx-5 mt-10">
    <div>
      <div>
        <div class="text-h5">
          <v-icon class="pr-1" @click="back">mdi-arrow-left</v-icon> {{ project.name }}
        </div>
        <div class="fz-14 openSans-400 gray-font-color my-1 pl-9" v-text="$vuetify.lang.t('$vuetify.projects.labels.intelligentLiteratureMonitoring')"></div>
      </div>
    </div>
    <div class="action-banner px-4 py-2 my-5">
      <v-btn depressed class="button-action-bar" :plain="showFilters ? false : true" :color="showFilters ? 'primary' : ''" @click="toggleFilters">
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
      <v-btn depressed plain class="ml-3 button-action-bar" @click="exportArticleList">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col v-show="showFilters" md="3">
        <filter-column @applyFilters="searchArticles" :sources="sources" :types="types"/>
      </v-col>
      <v-col :md="showFilters ? 9 : 12">
        <div v-if="articles.data.length != 0">
          <div class="d-flex justify-space-between">
            <div class="openSans-300 fz-18 mb-3">
              {{ `Articles: ${ currentPages } of ${articles.count} results` }}
            </div>
            <div>
              <span class="mr-8">
                <a v-if="order == 'desc'" @click="sortArticles('asc')">
                  <span class="black--text mr-1">{{ $vuetify.lang.t('$vuetify.ilm.labels.datePublished') }}</span><v-icon color="primary">mdi-arrow-down</v-icon>
                </a>
                <a v-else @click="sortArticles('desc')">
                  <span class="black--text mr-1">{{ $vuetify.lang.t('$vuetify.ilm.labels.datePublished') }}</span><v-icon color="primary">mdi-arrow-up</v-icon>
                </a>
              </span>

              <a class="text-uppercase ml-1" @click="allExpanded = !allExpanded">
                <span v-if="allExpanded">{{ $vuetify.lang.t('$vuetify.common.buttons.collapseAll') }}</span>
                <span v-else>{{ $vuetify.lang.t('$vuetify.common.buttons.expandAll') }}</span>
              </a>
            </div>
          </div>
          <div class="gray-border-container">
            <article-card v-for="article in articles.data" :article="article" :key="article.id"
                          :set-starred-article="setStarredArticle" :all-expanded="allExpanded"
                          :get-full-article="getArticle" :clear-stars="clearAllStars"/>
          </div>
          <v-pagination depressed v-if="totalPages > 1" class="my-4" v-model="articles.page" :length="totalPages" :total-visible="10"
                        @input="changePage" @previous="prevPage" @next="nextPage"></v-pagination>
        </div>
        <div v-else-if="searching" class="d-flex justify-center mb-6">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else class="d-flex justify-center mb-6">
          <v-alert v-if="articles.data.length == 0" color="blue" dense dismissible outlined prominent text
                   type="warning">No articles found</v-alert>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ProjectService from "@/modules/projects/services/ProjectService.js"

import FilterColumn from '@/modules/intelligentLiteratureMonitoring/components/FilterColumn.vue'
import ArticleCard from '@/modules/intelligentLiteratureMonitoring/components/ArticleCard.vue'

export default {

  name: 'Project',
  components: { FilterColumn, ArticleCard },
  data () {
    return {
      showFilters: true,
      articles: {
        data: [],
        page: 1,
        size: 1,
        count: 1
      },
      totalPages: 0,
      sources: [],
      types: [],
      project: {
        name: ""
      },
      order: "desc",
      currentFilter: {},
      searching:false,
      allExpanded: false,
      clearAllStars: false
    }
  },
  computed: {
    currentPages() {
      let pageArticles = this.articles.size * this.articles.page
      return `${ this.articles.size * (this.articles.page - 1) + 1 }-${ pageArticles > this.articles.count ? this.articles.count : pageArticles }`
    }
  },
  methods: {
    back() {
      this.$router.push(`/projects`)
    },
    exportArticleList() {
      let filters = {
        ...this.currentFilter,
        size: 100
      },
      routeData = this.$router.resolve({
        name: 'ExportIlmProject',
        params: {
          projectId: this.$route.params.projectId
        },
        query: {
          filters: JSON.stringify(filters)
        }
      })
      window.open(routeData.href, "Export Project", "resizable,scrollbars,status")
    },
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    sortArticles(order) {
      this.order = order
      this.searchArticles({ ...this.currentFilter })
    },
    searchArticles(criteria) {
      this.currentFilter = criteria
      this.currentFilter["sort_order"] = this.order
      this.articles.data = []
      this.searching = true
      ProjectService.searchArticles(this.$route.params.projectId, this.currentFilter).then(response => {
        this.articles = response.data
        this.types = response.data.types
        this.sources = response.data.data_sources.length != 0 ? response.data.data_sources[0] : []
        this.totalPages = Math.trunc(response.data.count / response.data.size)
        this.totalPages += (response.data.count % response.data.size) > 0 ? 1 : 0
      }).finally(() => this.searching = false)
    },
    getArticle(articleId) {
      return ProjectService.getArticle(this.$route.params.projectId, articleId)
    },
    setStarredArticle(articleId, starred) {
      ProjectService.setStarredArticle(this.$route.params.projectId, articleId, starred)
    },
    getProject() {
      ProjectService.get(this.$route.params.projectId).then(response => {
        this.project = {name :response.data.data.project_name}
      })
    },
    changePage(pageNumber) {
      this.allExpanded = false
      this.currentFilter.page = pageNumber
      this.searchArticles(this.currentFilter)
    },
    nextPage() {
      this.changePage(this.articles.page)
    },
    prevPage() {
      this.changePage(this.articles.page)
    }
  },
  created() {
    this.searchArticles({})
    this.getProject()
  }
}
</script>

<style lang="css" scoped>

  .gray-font-color {
    color: #979797;
  }

  .button-action-bar {
    width: 40px !important;
    min-width: unset !important;
    padding: 0px !important;
  }
</style>
