<template>
  <div>
    <div class="fz-16 openSans-300 mb-4 txt-primary-black " v-text="label"></div>
    <v-autocomplete :ref="propertyName" v-model="autocomplete" :items="items" :label="placeholder" outlined dense
                    :search-input.sync="search" :hide-details="true" @focus="triggerSearch" @input="addItem">
      <template v-slot:no-data>Please type to find matches</template>
      <template v-slot:append>
        <v-icon>mdi-magnify</v-icon>
      </template>
    </v-autocomplete>
    <v-chip v-for="item in selected" :key="item" class="mr-2 mt-2 openSans-400 fz-14 filter-chips" label>{{ item }}  
      <v-icon @click="removeItem(item)" class="chip-button" dense right>mdi-close-circle-outline</v-icon>
    </v-chip>
  </div>
</template>

<script>
import ProjectService from "@/modules/projects/services/ProjectService.js"

export default {

  name: 'FilterAutocomplete',

  props: {
    model: Array,
    label: String,
    filters: Object,
    propertyName: String,
    placeholder: String,
    onChange: Function
  },
  data () {
    return {
      autocomplete: null,
      search: null,
      isLoading: false,
      items: []
    }
  },
  methods: {
    triggerSearch(){
      this.search = ""
    },
    addItem(item) {
      this.clearSelect()
      if (item && this.selected.indexOf(item) == -1) {
        this.items = []
        this.selected.push(item)
      }
      this.onChange()
    },
    removeItem(item) {
      const index = this.selected.indexOf(item)
      if (index > -1) {
        this.clearSelect()
        this.selected.splice(index, 1)
      }
      this.onChange()
    },
    clearSelect() {
      this.$refs[this.propertyName].clearableCallback()
    }
  },
  computed: {
    selected: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
      }
    }
  },
  watch: {
    selected(value) {
      if (value.length == 0) {
        this.clearSelect()
      }
    },
    search(inputValue) {
      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      let filters = { ...this.filters }
      filters[this.propertyName] = inputValue
      ProjectService.filtersAutocomplete(this.$route.params.projectId, filters).then(response => {
        this.items = response.data
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="css" scoped>
</style>